export default class Header {
	constructor() {
		this.header = document.querySelector('.header')
	}

	init() {
		window.lenis.on('scroll', (event) => {
			if (event.scroll > 40) {
				this.header.classList.add('scrolling')
			} else {
				this.header.classList.remove('scrolling')
			}
		})
	}
}
