import Lenis from '@studio-freight/lenis'
history.scrollRestoration = 'manual'

// Smooth scroll
const lenis = new Lenis()

function raf(time) {
	window.lenis.raf(time)
	requestAnimationFrame(raf)
}

requestAnimationFrame(raf)
window.lenis = lenis
