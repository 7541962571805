export default class Hamburger {
	constructor() {
		this.hamburger = document.querySelector('.hamburger')
		this.navbar = document.querySelector('.navbar')
		this.body = document.querySelector('body')

		this.toggleActive = this.toggleActive.bind(this)
	}

	init() {
		this.addEvents()
	}

	addEvents() {
		this.hamburger.addEventListener('click', this.toggleActive)
	}

	toggleActive() {
		this.hamburger.classList.toggle('active')
		this.navbar.classList.toggle('active')

		if (this.navbar.classList.contains('active')) {
			window.lenis.stop()
		} else {
			window.lenis.start()
		}
	}
}
