export default class BackToTop {
	constructor() {
		this.button = document.querySelector('.backToTop')
		this.toggleButton = this.toggleButton.bind(this)
		this.scrollToTop = this.scrollToTop.bind(this)
		this.offset = 300
	}

	init() {
		this.addEvents()
	}

	addEvents() {
		window.addEventListener('scroll', this.toggleButton)
		this.button.addEventListener('click', this.scrollToTop)
	}

	toggleButton() {
		this.button.classList[window.scrollY > this.offset ? 'add' : 'remove']('show')
	}

	scrollToTop() {
		window.lenis.scrollTo(0, {
			duration: 2,
			easing: (x) => {
				return x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2
			}
		})
	}
}
